import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Markdown from './Markdown';

function NewsMain(props) {
  const { post, postMeta } = props;

  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        '& .markdown': {
          py: 3,
        },
      }}
    >
      <Typography variant="h6" gutterBottom>
        {postMeta.title}
      </Typography>
      <Divider />
        <div style={{
          "textAlign": "left",
        }}>
          <Markdown className="markdown" key={post.substring(0, 40)}>
            {post}
          </Markdown>
        </div>
    </Grid>
  );
}

NewsMain.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

export default NewsMain;
