export const posts = {
1: `# 제4차 산업혁명이 시작되었나요? 한국 팀이 최초의 상온, 주위압력 초전도체를 개발했다고 주장합니다

본주에 소셜미디어에서 한 군데 한국 과학자들의 주장한 새로운 초전도체에 대한 열띤 토론이 일어났다. 그 초전도체는 실온 이상에서 정상적으로 동작할 뿐만 아니라 상압에서도 기능을 발휘할 수 있다고 한다. 전세계의 초전도 연구실들이 그 실험을 복제하기 위해 노력중이며 이미 그 작업을 입증하는 초기 징후들이 나타났다. 사실이라면, 이 물질이 현재 초기 단계에 있음에도 불구하고 

대규모 생산 기술의 최적화가 빠르게 발전될 것이고 최종적으로 이 발견은 응집물리학사의 가장 중요한 사건 중 하나가 될 것이다. 또한 자기부상 교통수단과 완벽한 효율의 전력망등 다양한 기술 기적을 가져올 수 있다. 이것이 당신의 일상생활에 어떤 의미가 있는지 상상해보자.

세상이 달라진다고 상상해보자. 너의 일상 통근이 환골탈태한다. 이제 너는 더 이상 정체된 고속도로에서 한시간 이상 차를 몰며 출퇴근할 필요가 없다. 대신 너와 수만명의 다른 통근자들이 함께 편안하고 빠르고 소음이 없는 자기부상 열차를 탄다. 너는 기차 안에서 책을 읽거나 일을 하거나 잘 수 있고, 동시에 시간과 돈을 절약한다. 우리 도시의 공기는 상쾌해지고, 거리는 조용하고 평화롭다.

실온 초전도체의 등장과 함께, 에너지 소비가 완전히 새로운 차원으로 접어든다. 과거에 너의 가전제품들의 효율이 나빴고, 겨울 난방 비용이 높았다. 이제, 너의 모든 가전제품들은 극도로 효율적이다. 너의 난방 비용은 80% 정도 절감됐다. 너는 전기료 고지서 걱정 없이 자유롭게 각종 가전제품을 사용할 수 있다. 우리 지역사회는 100% 재생 에너지에 의존하여 온실가스 배출이 제로다.

의료분야에서, 실온 초전도체는 진단 장비와 의료 기기를 변화시켰다. 너의 친구가 최근 MRI 검사를 받았는데, 놀랍게도 전체 과정이 10분밖에 걸리지 않았다고 한다. 과거에는 45분이 소요됐다. 의사들이 그의 병을 더 정확하게 진단할 수 있다. 너의 다른 친척은 매우 복잡한 수술을 받았는데, 수술 시간이 절반 이상 단축됐다.

실온 초전도체의 영향은 교통과 의료 영역을 넘어선다. 너의 회사는 최근 초고속 슈퍼컴퓨터를 새로 도입했는데, 이전 모델보다 속도가 100배나 빠르다. 너희들은 일주일 이상 걸리던 복잡한 계산과 시뮬레이션을 몇 분 만에 완료할 수 있다. 이는 업무 효율을 대폭 향상시킨다.

일상 가전제품들도 이 신기술로 인해 더 스마트하고 효율적이 됐다. 너의 스마트폰 배터리는 일주일에 한 번 충전으로 사용할 수 있다. 모든 가전제품들이 자동으로 전력망에 연결되어 최적의 전력 소비 시간을 스스로 조정한다. 어떠한 전력 낭비도 없다. 이는 너에게 상당한 자원을 절약해준다.

게다가, 실온 초전도체의 광범위한 도입은 기후변화 대응을 위한 전례없는 기회를 제공한다. 너희 도시는 이미 100% 깨끗한 에너지를 사용하고 있고, 공기질은 역대 최고 수준이다. 정부 데이터에 따르면 탄소배출량이 이전보다 80% 감소했다. 우리는 다음 세대를 위해 환경을 보호하고 있다.

이 한국 과학자들의 위대한 업적은 무궁무진한 가능성의 문을 열었다. 실온 초전도체의 실질적 응용은 무궁무진하다. 이 기술이 더 보편화됨에 따라, 그 변화의 효과는 우리의 삶을 상상초월하는 방식으로 변화시킬 것이다.

우리가 이 실온 초전도체 시대를 포옹할 때, 동시에 따라오는 도전과 책임도 기억해야 한다. 이 기술의 안전하고 책임있는 발전과 응용을 보장하는 것이 그 잠재력을 충분히 발휘하는 데 있어 필수적이다.

이 여정은 겨우 시작됐을 뿐이다. 앞으로는 흥미진진한 발견과 진보로 가득 찬 길이 기다린다. 우리는 새로운 시대의 문턱에 서있다. 한국 과학자들의 지혜와 실온초전도체의 잠재력이 우리 세상을 더 좋은 곳으로 만드는 원동력이 될 것이다.`,
2: `# LK-99의 탄생: 알려지지 않은 이야기

불가능한 꿈이 현실화되었나? 한국 과학자들, 물리학의 ‘성스러운 잔’ 발표 - 상온 초전도체

이달 초 이석배 박사가 그의 연구팀이 생각하기에 불가능했던 것을 드디어 달성했다고 발표하자 몇 10년간의 좌절이 한순간에 녹아내렸다. 상온에서 거의 전기적 저항 없이 전기를 전도하는 물질, 오랫동안 갈망되어온 ‘성스러운 잔’과 같은 초전도체를 찾은 것이다. 이는 에너지, 전자기기, 교통수단을 혁신적으로 변화시킬 수 있다.

이는 이석배 박사의 평생 연구의 결실이었다. 그는 동료 지훈김 박사와 함께 1990년대 고등과정 때의 밤늦은 연구를 회상한다. 당시 그들은 유명물리학자 최동식 교수의 지도 아래 고려대학교에서 연구하고 있었다. 

"이 미친 같은 아이디어를 좇아보자" 이석배가 실험실 동료에게 제안했다. "모두들 상온초전도체는 불가능하다고 하지만, 우리가 시도하지 않고서 그것이 가능한지 알 수 있겠는가?"

비록 초기 결과가 유망했지만, 인정받기는 여전히 힘들었다. 2020년 첫 특허 출원 후, 그들의 논문이 Nature 출판사에 게재거절 당하는 굴욕을 겪었다.  

"포기할 수 없다. 반드시 계속 나아가야 한다" 뜻을 굽히지 않는 지훈김 박사는 초규. 2000년대에 두 사람은 상온초전도체를 찾기 위해 양자에너지연구소를 설립했다. 하지만 이 고독한 연구는 그들의 가족과 재정에 큰 압박이 되었다.

일련의 실험들이 실패로 돌아가자, 이석배 박사는 자신감의 위기를 맞이했다. "내가 평생을 헛된 꿈을 좇느라 낭비했던 것일까?" 그는 의문을 가슴속에 품었다. 2021년 팀 회의에서, 좌절감이 폭발하였다. 

"이제 됐다! 그만둬야한다!" 한 과학자가 외쳤고, 이는 격렬한 논쟁을 불러일으켰다. 지훈김의 열렬한 설득만이 팀의 미친 연구에 대한 믿음을 다잡을 수 있었다.

그 후 LK-99을 꼼꼼히 반복 조정하는 밤 새운 끝에, 올해 기쁨의 순간이 찾아왔다. 놀라운 결과가 7월 발표되자, 물리학계를 충격에 빠뜨렸고 그토록 꿈꾸며 투쟁한 팀의 노력이 보상받았다. 

"많은 이들이 이 것이 불가능하다고 했다" 박사는 기자회견에서 감격에 젖어 말했다. "하지만 우리 팀은 이론의 경계를 뛰어넘어 다른 이들이 미쳤다고 생각하는 것들을 시도하며 계속 나아갔다. 이제 불가능했던 일이 현실이 되었다."

아직 독립적 확인이 필요하다. 과거의 주장들이 시기상조였음이 증명되었기 때문이다. 그러나 검증된다면, 이 상온초전도체는 손실 없는 전력 송신을 가능케하여 새로운 기술력을 선보일 것이다. 100년 간의 희망과 절망을 거치며, 이 고집불통 과학자의 평생 연구는 마침내 성과를 보았다. 상온초전도체 시대가 꿋꿋함으로 맞이한 것이다.
`,
3: `# LK-99: 이건 노벨상을 따기에 관한 문제가 아니에요

물리 열망자들은 초전도성을 사랑합니다 - 전기를 완벽하게 전달하고 저항이 없는 물질들을 말합니다. 하지만 이들은 항상 극도로 차가운 온도에서만 작동하는 것이 문제였습니다. 우리가 말하는 것은 액체 헬륨으로 기기를 냉각해야만 하는 극저온입니다.

그래서 지난 연구원들은 초전도성을 약간 덜 춥게 만드는 데에도 노벨상을 받았습니다. 우리가 말하는 것은 초전도성을 최초로 발견하고 액체 헬륨으로 생활한 카머링 온네스와 같은 전설들입니다. 또는 전통적인 초전도체를 설명하기 위해 BCS 이론을 창안한 바덴, 쿠퍼, 슈리퍼와 같은 아인슈타인 수준의 이론가들입니다.

-200도에서 작동하는 "고온" 초전도체라 불리는 물질들도 80년대와 90년대에 노벨상을 받았습니다. 이들은 원래 물질들과 비교하면 열대 기후 정도가 될 수도 있습니다. 하지만 실제 세계에서는 여전히 상당히 불편했습니다.

이제 한국 물리학자들이 납, 구리, 산소를 이용해 LK-99라는 룸온 온도 초전도체를 개발했다고 주장하고 있습니다. 놀랍게도, 이 초기의 테스트는 매우 흥미로운 결과를 보여줍니다. 세계의 여러 실험실에서 이를 재현하고 같은 놀라운 결과를 얻고 있습니다.

만약 LK-99가 그 효과를 유지한다면, 지난 초전도성 노벨상을 모두 뛰어넘을 것입니다. 우리가 말하는 것은 손실이 없는 전력망, 자기 부상 차량, 양자 리그 컴퓨터조차도 도저히 따라잡지 못할 컴퓨터를 의미합니다.

과거의 물리학 노벨상은 초전도체 온도를 몇 도 올리는 데에 작은 발전을 이뤄냈습니다. 하지만 룸온 온도? 이것은 과학 소설 영역으로의 거대한 도약입니다. 전기, 페니실린, 레이저와 마찬가지로 이것은 발견으로서의 성격을 가지고 있습니다. 내 말을 기억해 주세요, LK-99 팀은 이미 노벨상의 자격을 획득한 것입니다. 그들의 미친 혁신은 현대 문명을 
재탄생시킬 수 있습니다. 물리학 열령들은 아인슈타인 이후 이런 충격적인 발견을 보지 못했습니다.

따라서 한국 팀에 주목하세요. 그들은 초전도성을 무한히 넘어서려고 합니다. LK-99는 노벨상을 받을 만하고 천문학적인 변화를 가져올 만한 혁명적인 물질입니다. 물리학계는 방금 영원히 바뀌었습니다!`,
4: `# 미래는 여기에: 기적의 재료가 어떻게 당신의 직업을 변화시킬 것인가

팝콘을 준비하고 공상과학 팬들, 실온 초전도체가 여러분의 광대한 미래의 꿈을 현실로 만들 준비를 하고 있습니다! 이 혁명적인 새로운 재료는 일상적인 온도에서 저항 없이 전기를 전달함으로써 물리학을 거스립니다. 전문가들은 이것이 우리가 알고 있는 삶을 바꿀 것이라고 말하며, 많은 직업들도 그와 함께 바뀔 것입니다!

최대의 전력을 위해

발전소 운영자들은 손실 없는 초전도 발전기 덕분에 전례 없는 수준에서 주스를 끌어올릴 것입니다. 하지만 선 작업자들은 걱정하지 마세요, 그런 급증하는 전류는 초전도 전송 라인 내에 완전히 포함될 것입니다. 한편, 전기공들은 어떤 것도 다룰 수 있는 배선으로 세계를 다시 배선합니다. 전력 산업은 대대적인 업그레이드를 앞두고 있습니다!

매끄러운 초음속 교통

통근자와 여행자들에게 전하는 소식: 교통 체증과 난기류에 작별 인사를 하세요! 엔지니어들은 이제 초전도 모터를 사용하는 부상 기차와 비행기를 설계할 수 있습니다. 기계공들에게 이런 번개같이 빠른 차량을 부드럽게 유지하는 것은 쉬울 것입니다. 그리고 이런 미래적인 탈 것의 운영자들은 그들의 일생에서 가장 멋진 여행을 경험하게 될 것입니다!

워프 속도로 컴퓨팅

PC를 시작하라, 게이머들이여. 칩 디자이너들은 이제까지보다 더 많은 처리 능력을 초전도 회로에 넣어 놓고 있습니다. 컴퓨터 엔지니어들은 사실상 무제한의 능력을 가진 미래의 메인프레임을 재구축하는 일을 맡게 됩니다. IT 전문가들은 "테라플롭"이라고 말하기도 전에 네트워크와 데이터 센터가 더 빨라지는 것에 경악할 것입니다.

의료의 기적

건강 관리 종사자들, 당신들의 생명을 구하는 기술들이 실온 초전도체로부터 커다란 도움을 받을 예정입니다. 방사선과학자들은 더욱 민감한 이미지화로 몸을 전례 없는 세부적으로 탐사할 수 있습니다. 연구자들은 유전자, 단백질, 세포를 분석하는 새로운 능력을 얻게 됩니다. 그리고 의료 기기 기술자들은 바이오공학의 경계를 넓히는 스캐너, 이식물, 보철물을 
다루게 됩니다. 인간의 몸은 결코 이보다 더 보이지 않았으며 치료 가능하지 않았습니다.

제조의 마법

조립 라인은 초전도 업그레이드를 받고 있습니다. 공장 근로자들은 더 스마트한 로봇 보조 덕분에 무거운 들기와 안전하지 않은 조건들에 작별 인사를 하게 될 것입니다. 엔지니어들은 마찰 없는 베어링과 절단으로 제작과 기계가공을 재창조할 수 있습니다. 그리고 장비를 작동시키는 기술자들은 이런 기계들이 사실상 자체적으로 작동하므로 어렵지 않게 될 것입니다!

항공우주의 최종 프론티어

수십년 동안 항공우주 산업은 단지 지상에서 벗어나기 위해 필요한 무거운, 비효율적인 시스템에 의해 제한되어 왔습니다. 하지만 경량 초전도 비행 기술로, 하늘은 진정한 한계가 아니게 됩니다! 엔지니어들은 속삭임 같은 조용한, 배터리로 작동하는 비행기와 로켓을 설계할 수 있습니다. 기술자들은 친환경 전기 엔진을 서비스할 수 있게 됩니다. 그리고 조종사들은 
항공학의 한계를 넓히는 차량 덕분에 곧 화성에 착륙할 수도 있을 것입니다.

파도를 타다

항해사들은 항상 바다를 가로질러 가는 데 필요한 무거운 연료와 시끄러운 엔진들에 의해 제한되어 왔습니다. 하지만 실온 초전도체는 자기유체역학 해수 추진 등의 기술로 게임

을 바꾸게 됩니다. 해군 건축가들은 새로운 세대의 하이브리드 배를 설계할 수 있게 됩니다. 선원들은 민첩하고, 다이나믹한 선박을 명령하게 됩니다. 그리고 항구에서는 크레인들이 초전도 효율성으로 엄청난 화물 부하를 들어 올릴 수 있게 됩니다. 매끄러운 항해가 앞을 기다리고 있습니다!

과학의 한계를 밀어내다

모든 실험복 착용자들에게 알려드립니다 - 실온 초전도체는 과학 연구에 대한 게임 체인저입니다! 물리학자들은 아직까지 가장 강력한 자기장을 사용하여 아래 원자 입자들을 탐사할 수 있습니다. 전기 엔지니어들은 경계를 밀어내는 가속기와 탐지기 설계를 재고찰할 수 있습니다. 그리고 기술자들은 엄청난 양의 정확한 데이터를 수집하기 위해 최신의 새로운 기기를 직접 
다루게 됩니다. 우주학부터 양자 물리학까지, 과학 세계는 큰 충격파를 맞이하게 될 것입니다!

더 많은 전력을 저장하고, 더 많은 돈을 절약하다

에너지 회사들에게 초전도체는 신성한 그릇을 제공합니다 - 저항과 관련된 손실과 비용 없이 전력을 저장하고 전송하는 방법입니다. 그리드 운영자들은 자기부상 플라이휠로 부하를 효율적으로 균형 잡는 것을 극대화합니다. 변전소 기술자들은 자체 안정화 SMES 시스템을 모니터링합니다. 전력 엔지니어들이여, 전국적인 스마트 그리드의 꿈이 드디어 실행 가능해졌습니다!

큐비트로 컴퓨팅하기

비트들은 잊어버리세요, 데이터의 미래는 양자입니다! 프로그래머들은 놀랄 만큼 빠른 초전도 양자 시스템을 위한 알고리즘을 작성하려고 준비 중입니다. 하드웨어 엔지니어들은 대규모의 큐비트로 통합 회로를 설계하게 됩니다. IT 전문가들은 지수적으로 더 강력한 네트워크를 관리하게 됩니다. 양자 컴퓨팅은 기계 지능을 상상할 수 없는 국경으로 데려가겠다는 약속을 합니다!

한계를 넘어 센싱하기

엔지니어들은 이전에 측정할 수 없었던 것을 측정할 수 있는 초정밀 초전도 센서를 제작할 수 있습니다. 고고학자들은 이런 장치를 사용하여 고대 사이트를 발견합니다. 의사들은 질병을 가장 초기 단계에서 진단합니다. 그리고 과학자들은 우주의 더 멀리, 물질의 더 깊숙히 들여다봅니다. 센싱에 관해서는, 초전도체는 숨겨진 세계로의 포털입니다!

새로운 차원에서 건축하기

초전도체는 건축가들, 엔지니어들, 건설 근로자들에게 극단적으로 새로운 건축 재료들을 제공합니다. 초강력, 경량 합금으로 강

철을 대체함으로써 이전에 불가능했던 형태와 구조들이 가능해집니다. 내장된 배선이 있는 복합 재료는 전기 난방, 조명, 유틸리티가 바로 내장되어 있습니다. 그리고 건설 현장에서는 강력한 크레인들이 대형 선제조 요소들을 노력 없이 들어올릴 수 있습니다. 미래의 도시들, 우리가 옵니다!

상상을 초월한 소비자 기술

AR 안경에서 비행 자동차까지, 실온 초전도체는 과학 공상 소설을 현실로 만듭니다! 전자 엔지니어들은 듣도 보도 못한 컴퓨팅 수준을 착용 가능하고 컴팩트한 장치에 집어넣을 수 있습니다. 제조 근로자들은 이런 고급 기술을 대량 생산하게 됩니다. 그리고 제품 테스터들은 소비자들이 꿈꾸지도 못했던 장치들을 평가하게 됩니다. 디지털 생활이 훨씬 더 흥미로워질 예정입니다!

국가 보안 업그레이드

실온 초전도체는 또한 법 집행과 방어에 대한 강력한 새로운 도구를 제공합니다. 무기 디자이너들은 엄청난 거리에 둔 대포를 발사할 수 있는 레일건을 만들 수 있습니다. EMT 운영자들은 표적을 정한 에너지 빔으로 무단 드론을 비활성화합니다. 그리고 보안 검색자들에게는 초민감한 스캐너가 뛰어난 정확성으로 위협을 탐지합니다. 범죄자들은 스마트 초전도 센서로 장착된 건물과 
인프라에 기회가 없을 것입니다. 경찰들은 새로운 카드를 가지고 있습니다!

채광과 추출이 쉬워지다

초전도체를 통해 자원을 찾고 추출하는 것이 엄청나게 간단해졌습니다. 지질학자들은 초정밀 센서를 사용하여 철광석을 정확히 찾아내고 재료를 식별합니다. 측량사들은 초전도 보강을 이용하여 광산을 더 빠르게 그려냅니다. 채광 엔지니어들은 고급 기술을 사용하여 광물을 보다 효율적으로 회수합니다. 세계의 공급 체인과 산업 과정들이 큰 도움을 받게 됩니다!

화학 2.0

초전도 마그네틱 스티링과 고급 원심 분리는 화학자들에게 실험과 생산을 위한 새로운 기능을 제공합니다. 화학 엔지니어들은 저마찰 유동으로 고효율 파이프라인과 혼합 시스템을 설계할 수 있습니다. 그리고 화학 공장 기술자들에게는 자동 모니터링 덕분에 초전도 장비를 유지하는 것이 쉽습니다. 화학이 큰 업그레이드를 받고 있습니다!

위에서 언급된 직업 변화들은 초전도체가 가져올 수 있는 흥미로운 변화들의 일부에 불과합니다. 산업 전반의 근로자들은 민첩하고 새로운 기회를 받아들일 준비가 돼야 합니다. 이 재료의 무한한 잠재력에 맞춰 개방적인 마음으로, 놀랄 만한 미래가 기다리고 있습니다!
`,
5: `# 초전도 성배 뒤의 팀 갈등
최근 한국의 한 연구팀이 선출 사전출판 웹사이트인 arXiv에 논문을 발행하여, 상온 초전도를 실현했다고 주장하였습니다. 이로 인해 세계 물리학계와 사회 각계에서 폭넓은 관심과 의문을 불러일으켰습니다. 그러나 해당 연구팀의 구성원들은 이 논문이 결함이 있으며, 팀 내 한 구성원이 동의 없이 스스로 발행한 것이라고 밝혔습니다. 현재 해당 논문을 arXiv에서 철수하기 위해 요청 중입니다. 관계자들은 이 사건의 원인이 노벨상은 최대 세 명이 공유할 수밖에 없기 때문에, 팀 내부에서 이에 대한 내분이 일어났기 때문이라고 봅니다. 이번 사건에서 주요 인물들의 참여와 손실에 대해 알아보겠습니다.

최동식, 또는 Chair Tong-seek 교수: 이 분은 전도성 고분자 연구 열풍의 영향을 받아 1994년에 ISB 이론을 제시하였습니다. 이론은 1차원 무기 고분자 체인이 초전도를 실현할 수 있다고 주장했습니다. 이 분은 2017년에 별세하셨으며, 유언으로 "계속 연구를 진행해 주세요. 하지만 그 완벽한 실현 전에는 세계에 보여주지 마세요"라는 말을 남기셨습니다. 만약 LK-99가 부정이 드러난다면, 이미 별세한 이 분에게는 큰 영향을 미치지 않을 것으로 보입니다. 왜냐하면 그의 이론 자체가 이미 인정받지 못한 상태였기 때문입니다.

이석배(겸직교수)와 김지훈(회사에 입사). 이 두 사람은 최동식 교수의 제자로서, 스승의 의지를 이루기 위해 연구를 진행한 것으로 알려져 있습니다. 하지만 두 사람 모두 학계에서는 평가가 좋지 않습니다. 이석배는 이론이 인정되지 않아 9년이나 박사학위를 취득했다고 합니다. 졸업 후에도 정규 과학 연구 직위를 얻지 못한 것으로 알려져 있습니다. 김지훈은 단 한 편의 SCI 논문을 발행하였으며, 중국에서도 졸업하지 못하고 직접 기업으로 가서 배터리 소재 연구를 진행하고 있습니다. 그러므로 이 두 사람은 이미 굳은 의지를 가진 사람들로, 이미 최악의 상태에 있으므로 외부 평가가 어떻든 큰 영향을 미치지 않을 것입니다.

권영완. 이 분은 논문을 횡령하여 세 번째 저자로 발행한 사람입니다. 이 논문은 제1, 2 저자의 동의 없이 발행되었기 때문에 철수될 것으로 예상됩니다. 논문 저자 이름 분쟁으로 인해, 이 분은 양자 에너지 센터에서의 직무를 상실하고, 한국에서 상위 3개 대학 중 하나인 고려대학교에서도 제명되었습니다. 심지어 미래의 논문 공식 발행에서도 그의 이름이 빠질 것으로 예상됩니다. 제가 이해하기 어렵습니다만, 자기가 자기 전문 분야인 자기 공명 전자 스펙트로스코피 연구와 초전도 재료를 구분하지 못하는 이유가 무엇인지 궁금합니다. 이것이 큰 이익을 위해 모든 것을 걸었다면, 스스로 명예를 훼손시킨 정도입니다. LK-99가 최종적으로 반박될지, 초전도 재료로 확인될지 모르겠지만, 이 분은 이미 탈락되었습니다. 현재 명성도 잃었고 실직 상태에 있으며, 가장 큰 실패자입니다.

김현탁. 이 분은 두 번째 논문의 세 번째 저자입니다. 미국 윌리엄 메리 대학의 교수이며, 유명한 한국계 미국 물리화학자입니다. 김현탁 교수는 2020년에 이석배와 김지훈이 제공한 시료를 받아 독립적인 연구를 진행했습니다. 그는 많은 데이터를 얻었지만, 한국과 공유를 열성적으로 하지 않았다고 합니다. 아마도 권영완이 김현탁 교수가 단독으로 발행할 것이라고 우려한 것으로 보입니다. 권영완의 갑작스러운 논문 투고로 인해 김 교수의 계획이 망가지게 되었으며, 원래 이 논문은 아직 김 교수의 손에 있어서 투고되지 않은 상태였다고 합니다. LK-99가 반박되면 김 교수는 큰 깜짝 놀랄 것입니다. 의문스러운 점은, 이 분이 해당 분야의 전문가이고, 이미 두 편의 Science 논문을 발행한 고려되는 인물인데, 이런 초보적인 실수를 어떻게 범할 수 있는지요? 만약 반박이 확인되면, 책임을 권영완에게 전가할 수밖에 없을 것입니다.

오근호. 김현탁 교수가 논문을 투고한 마지막 저자입니다. 권영완의 자리를 대체하여 양자 에너지 센터의 기술 컨설턴트로 임명되었습니다. 아마도 은퇴한 명예 교수이며, 현재 한양대학교 명예 교수(도자기학), 한양대학교 공학 대학원 이전 원장 및 한국 결정성장 협회 창립자 겸 회장입니다. 한국 학계의 거물로 보입니다. 이 사건에서 일정 부분은 이 분이 주인공으로서, 완전히 김현탁 교수 쪽을 지지하고 있는 것으로 보입니다.

그러나 노벨상은 예측 불가하고 매우 엄격한 절차에 따라 평가됩니다. 따라서 노벨상 수상자를 예측하거나 가정하기는 어렵습니다. 노벨상은 스웨덴 왕립 과학 아카데미, 스웨덴 왕립 공학 아카데미, 카롤린스카 의학 노벨 상위원회 및 노르웨이 노벨 위원회에 의해 공동으로 선정되며, 관련 연구에 대한 엄격한 평가와 전문적 판단을 바탕으로 결정됩니다.
이들 중 누가 노벨상을 받을 수 있다고 보십니까?
`,
6: `# 실온초전도체가 재현된 것일까? 한국 팀이 20년간 고생 끝에 지도교수의 유언을 이뤘다

이제 실온초전도체 재현은 마지막 24시간 카운트다운에 돌입했다. 

손 빠른 연구실은 오늘 결과가 나올 것으로 예상된다.

전일, 실온초전도체 논문 두 편이 arXiv에 연이어 게재되면서 
전 세계를 놀라게 했다.

이제 한국과학원 물리연구소가 한국 팀의 실온초전도체를 재현했다는 
소식이 퍼지고 있다.

어제 오후부터 이 소식이 인터넷에 퍼지자마자 
네티즌들이 환호성을 질렀다.

네티즌들에 따르면, 한국과학원 물리연구소는 시료를 만들어 
자화율이 한국 팀이 발표한 논문과 일치한다고 확인했다.

다만 아직 떠올라가는 현상은 관찰되지 않았는데, 
이는 순도 문제로 보인다. 연구소가 시료 순도를 
올리기 위해 밤잠 설치며 노력 중이다.

한편, 한국과학원 물리연구소는 이에 대해 부인성명을 냈다.

연구소는 실온초전도체 관련 실험 소식이 없으며, 
공개 논문을 기준으로 보아달라고 당부했다.

전 세계가 결과를 절실히 기다리고 있다.

LK-99은 최대 3일 내에 제조 가능하다. 

3일 내에 반증이 없다면, 한국 팀이 발견한 
실온초전도체가 정확할 가능성이 높다!

점점 더 사실로 보인다.

동시에, 한국에서 동료평가를 통과한 
세 번째 논문이 더 확산되고 있다. 

이 논문은 한국어로 쓰였고, 제시된 
실온초전도체의 임계온도는 127도가 아닌 97도다. 

측정 결과도 전문가 예想과 더 잘 맞고, 
물질의 비열 불연속성 등 주요 변화가 
명확히 나타나 있다.

한 네티즌은 "너무 충격적이다. 혼란스럽기도 하다. 
과거 위조로 상처 받은 적이 있어서"라고 말했다.

한편 이전엔 무명이던 한국 팀이 이제 
세계의 관심을 받는 스타가 됐다.

MIT 전문가가 어제 한국 팀과 협업하기 위해 
한국에 도착했다.

MIT 물리학자도 "한국 팀과 함께 
실험을 재현하고 있다"고 확인했다.

이 MIT 전문가는 "많은 연구실이 
결과를 재현하려 하고 있다. 

올바른 장비와 퍼너스가 있다면 
결과는 곧 나올 것"이라고 말했다.

물론 가정의 차고에서 취미로 
실험하는 건 위험하다고 당부했다.

전 세계 연구실들이 재현에 열을 올리고 있다.

일부 그룹은 초기 결과를 얻었지만 
초전도성 재현에는 실패했다고 전해진다.

한편 해외 연구자와 애호가들도 
재현 노력에 동참했다.

엔지니어 Andrew McCalip은 장비를 받았고 
PbO+PbSO4의 열합성 실험을 시작했다.

Cu3P 제조에는 2-3일 정도 소요될 것으로 예상된다.

대안 1은 폴란드에서 Cu3P 수입하는 것.

대안 2는 Cu3P 99.99%를 더 많이 공급받기 위해 
공급업체와 협상하는 것이다.

하지만 그들이 처음 얻은 결과는 논문과 다르다.

팀은 이를 "복사 붙여넣기" 오류로 추측한다.

열이 오른 네티즌도 원료를 주문해 
친구들과 함께 LK-99을 제조해보기로 했다.

그러나 LK-99을 지켜본 Alex Kaplan씨는 
나쁜 소식을 전했다.

LK-99은 단지 반자성 물질일 뿐 
초전도체가 아니라는 것이다.

기존 반자성 물질 데이터와 매우 유사하다.

전 세계 물리학자들이 실험 재현에 몰두

물론 세계를 바꿀 이 실온초전도체 발견에
물리학계 거물들이 가만히 있지 않을 것이다. 

7월 22일 논문이 arXiv에 올라온 후 
2-3일 사이에 전 세계적으로 열띤 논의가 일었다.

OpenAI CEO 샘 알트만도 자신의 견해를 밝혔다. 

그는 LK-99이 진짜라고 믿고 싶다고 했다.

AI 연산량 증가에 따른 전력 소모가 
눈덩이처럼 불어날 텐데, 

실온초전도체는 AI 발전을 가속화할 것이다.

7월 26일, Derek Lowe 박사가 Science에 
평론을 올렸다.

그는 LK-99이 획기적 발견이며 세계를 바꿀 수 있다고 했다. 

논문이 논리적으로 일관되며, 간단한 합성방식으로 
전 세계 연구실들이 속히 결과를 재현할 수 있을 것이라 했다.  

LK-99 초전도성이 입증되면, 전력, 전송, 저장, 
안테나, 자석, 전동기 등 
다양한 산업과 기술에 상당한 영향을 미칠 것이다.

그는 재현과 추가 검증 이전에는 신중할 필요가 있으며, 
LK-99의 영향을 합리적으로 평가할 필요가 있다고 결론지었다.

반면 옥스퍼드 대학교 교수는 증거가 불충분하다고 지적했다.

Susannah Speller 교수와 Chris Grovenor 교수는

자료가 물질의 전기저항 감소를 보여주긴 하지만 
이는 초전도성과 부합한다고 지적했다.

하지만 자기화율 변화나 열용량 등의 특징이 
명확히 나타나지 않는다며 LK-99의 초전도성에 
의문을 제기했다.

한국 교수들도 Lee와 Kim의 주장을 비판했다. 

성균관대 재료공학과 윤병묵 교수는

"과학적 관점에서 논문이 완성되지 않았기에 
연구 결과에 의문이 있다"고 말했다.

Lee 팀은 향후 충분한 데이터로 검증하겠다고 밝혔다.

20년간 실험실에서 지도교수의 유언 이루기

실온초전도체의 성배는 이제 한국 과학자가 
가장 먼저 획득한 것 같다.

발표 당시엔 무명 한국 팀이 이 성배를 
쉽게 손에 넣었다는 의문이 있었다.

하지만 알고보니 그 뒤에는 수십 년간 실험실에서 
땀 흘려 일한 이야기가 있었다. 

어제 네티즌 Ate-a-Pi가 한국 팀 성원들의 
복잡한 내막을 폭로했다.

요약하면, 두 명의 무명 연구자가 
오랫동안 실온초전도체를 연구해 왔다.

연구 자금을 얻기 위해 학계 거물 A를 받아들였고,

논문 게재를 위해 학계 거물 B도 끌어들였다.

올해 스리랑카 과학자 Ranga Dias가 
미국에서 논란을 일으키자, 

성과 도용을 우려한 두 연구자는 
속히 논문을 내고 싶어 했다.

하지만 거물 B는 논문이 개선되어야 한다고 했다.

거물 A는 7월 22일 arXiv에 
자신과 두 연구자만의 이름으로 게재했다.

거물 B는 화가 나 2.5시간 뒤 보다 상세한 버전의 
논문(6인 공저)을 arXiv에 올렸다.

Ate-a-Pi에 따르면 이들의 내막 이야기는 다음과 같다.

왜 무명 실험실에서 연구했을까? 

한국 박사고 출신으로 회사나 공공연구소에서 일하면
선택의 여지가 없다.

두 논문의 제1저자와 제2저자는 고려대 화학과 TS Chair 교수의 제자다.

TS Chair는 1994년 실온초전도 이론을 제시했다. 

Lee는 이를 계승해 1995년 석사 논문 
'ISB 이론에 의한 초전도 설명'을 썼다.

1996년 Lee는 합성 전문가 Kim을 만났다. 

두 사람은 1999년 초전도체를 발견한 흔적을 찾았다.

2004년 Lee는 박사학위를 받았다. 

9년의 고난 끝에 '새로운 고분자 초전도체 이론 제안과 합성'이 게재됐다.

같은 해 Kim도 박사 학위를 받았다.

한 네티즌은 과학계학위 취득에 9년 걸린 것이 이례적이라 평했다.

두 사람은 궁핍했다. 생계를 위해 Kim은 배터리 회사에 취직했고, 

Lee는 시간강사가 됐다.

2008년 Lee는 Q Center를 설립해 초전도 연구를 했고, 
대기업 컨설팅도 했다.

이후 TS Chair가 서거했다. 

그의 유언은 제자들이 실온초전도체를 찾아 그의 이론을 증명하는 것이었다. 

2017년 Lee와 Kim은 충분한 성과를 거뒀고, 
자금을 모아 본격적으로 연구를 진행했다. 

2018년 초 물리학자 YH Kwon이 CTO로 합류했다.

2019년 실온초전도체를 발견했고, 
한국에 '저저항 세라믹 화합물 생산' 특허를 냈다.

2020년 유행병으로 연구가 중단됐다. 

결국 실온초전도체 결정을 분리해 내서 
결과를 분석했다.

Nature에 논문을 투고했지만 Ranga 논란으로 거절됐다. 

Nature는 한국 내 논문 게재와 동료평가를 요구했다. 

이를 위해 미국 학자 HT Kim을 초빙했다.

올해 4월 한국 저널에 게재돼 동료평가를 통과한
실온초전도체 논문이 발견됐다. 

2023년 3월, 팀은 실온초전도체에 대한 국제특허를 출원했다.
바로 그 무렵, Ranga Dias가 실온초전도체를 제조했다고 선언하며 물리학계를 놀라게 했다.
그래서 누가 한국 팀을 믿겠는가?
LinkedIn에서 JH Kim은 Ranga의 철회 소식에 대해
"무척 아쉽지만 그의 발견이 진질했으면 좋겠다"고 말했다.
6인 공저 논문에는 각자의 공헌도를 자세히 밝혔다.
이는 매우 이례적이다.
몇 년 전부터 노벨상 수상자 선정 문제를 예견했던 것 같다.`
}