import * as React from 'react';

import { useParams } from "react-router-dom";
import NewsMain from '../components/NewsMain';
import { posts } from '../data/posts_kr';

export default function News({allPostsMeta}) {
    const {newsIdStr} = useParams();
    const newsId = parseInt(newsIdStr);
    console.log("all posts meta is", allPostsMeta)

    const postMeta = allPostsMeta.find((item) => item.id === newsId);
    const newsContent = posts[newsId]
    if (newsContent != "") {
        return <NewsMain post={newsContent} postMeta={allPostsMeta} />
    } else {
        return <div>Loading</div>
    }
}