import * as React from 'react';

import Grid from '@mui/material/Grid';

import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';


import MainFeaturedPost from '../components/MainFeaturedPost';
import FeaturedPost from '../components/FeaturedPost';
import Sidebar from '../components/Sidebar';

const mainFeaturedPostId = 1

// const featuredPosts = [
//   {
//     id: 1,
//     title: '4차 산업혁명: 한국 팀이 최초의 상온, 주위압력 초전도체를 개발했다고 주장합니다',
//     date: '2023년 7월 28일',
//     description:
//       '이석배 퀀텀에너지연구소 대표·오근호 한양대 명예교수 연구진. 약 30도 상온에서도 초전도성 유지하는 물질 찾았다고 발표',
//     image: 'https://source.unsplash.com/random?wallpapers',
//     imageLabel: 'Image Text',
//   },
//   {
//     id:2,
//     title: 'LK-99의 미지의 이야기를 밝힙니다',
//     date: '2023년 7월 29일',
//     description:
//       'This is a wider card with supporting text below as a natural lead-in to additional content.',
//     image: 'https://source.unsplash.com/random?wallpapers',
//     imageLabel: 'Image Text',
//   },
//   {
//     id:3,
//     title: 'Will the team get Nobel prize?',
//     date: '2023년 7월 29일',
//     description:
//       'This is a wider card with supporting text below as a natural lead-in to additional content.',
//     image: 'https://source.unsplash.com/random?wallpapers',
//     imageLabel: 'Image Text',
//   },
//   {
//     id:4,
//     title: 'Investment opportunity in a world of superconductor',
//     date: '2023년 7월 29일',
//     description:
//       'This is a wider card with supporting text below as a natural lead-in to additional content.',
//     image: 'https://source.unsplash.com/random?wallpapers',
//     imageLabel: 'Image Text',
//   },
//   {
//     id:5,
//     title: 'Investment opportunity in a world of superconductor',
//     date: '2023년 7월 29일',
//     description:
//       'This is a wider card with supporting text below as a natural lead-in to additional content.',
//     image: 'https://source.unsplash.com/random?wallpapers',
//     imageLabel: 'Image Text',
//   },
//   {
//     id:6,
//     title: '한국 과학자들이 인류를 4차 산업혁명으로 이끌어갑니다.(韩国科学家们正在引领人类走向第四次工业革命。)',
//     date: '2023년 7월 29일',
//     description:
//       'This is a wider card with supporting text below as a natural lead-in to additional content.',
//     image: 'https://source.unsplash.com/random?wallpapers',
//     imageLabel: 'Image Text',
//   },
// ];

const posts = [];

const sidebar = {
  title: 'About',
  description:
    'Etiam porta sem malesuada magna mollis euismod. Cras mattis consectetur purus sit amet fermentum. Aenean lacinia bibendum nulla sed consectetur.',
  archives: [
    { title: 'March 2020', url: '#' },
    { title: 'February 2020', url: '#' },
    { title: 'January 2020', url: '#' },
    { title: 'November 1999', url: '#' },
    { title: 'October 1999', url: '#' },
    { title: 'September 1999', url: '#' },
    { title: 'August 1999', url: '#' },
    { title: 'July 1999', url: '#' },
    { title: 'June 1999', url: '#' },
    { title: 'May 1999', url: '#' },
    { title: 'April 1999', url: '#' },
  ],
  social: [
    { name: 'GitHub', icon: GitHubIcon },
    { name: 'Twitter', icon: TwitterIcon },
    { name: 'Facebook', icon: FacebookIcon },
  ],
};


export default function Home({featuredPostsMeta}) {
  console.log("featured posts are", featuredPostsMeta);
  const headlinePostMeta = featuredPostsMeta.find((item) => item.id === mainFeaturedPostId);
  console.log("headline:", headlinePostMeta)
  return (

        <main>
          <MainFeaturedPost post={headlinePostMeta} />
          <Grid container spacing={4}>
            {featuredPostsMeta.map((post) => (
              <FeaturedPost key={post.title} post={post} page_id={post.id}/>
            ))}
          </Grid>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            {/* <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            /> */}
          </Grid>
        </main>

  );
}
