import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Home from './pages/Home'
import News from './pages/News'
import Footer from './components/Footer';
import { assert } from 'chai';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

// const sections = [
//   { title: 'Technology', url: '#' },
//   { title: 'Design', url: '#' },
//   { title: 'Culture', url: '#' },
//   { title: 'Business', url: '#' },
//   { title: 'Politics', url: '#' },
//   { title: 'Opinion', url: '#' },
//   { title: 'Science', url: '#' },
//   { title: 'Health', url: '#' },
//   { title: 'Style', url: '#' },
//   { title: 'Travel', url: '#' },
// ];
const sections = []

function App() {
  const allPostsMeta = require('./data/news_kr.json')

  console.log("Rendering with post metadata", allPostsMeta)
  if (allPostsMeta != []) {
    return (
      <div className="App">
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <Container maxWidth="lg">
            <Header title="한국 과학자들이 인류를 4차 산업혁명으로 이끌어갑니다" sections={sections} />
          <Router>
            <Routes>
              <Route
                path="/"
                element={<Home featuredPostsMeta={allPostsMeta}/>}
              />
              <Route
                path="/news/:newsIdStr"
                element={<News allPostsMeta={allPostsMeta}/>}
              />
            </Routes>
          </Router>
          </Container>
          <Footer
            title="아직 오지 않은 더 흥미로운 소식..."
            description=""
          />
        </ThemeProvider>
      </div>
    );
  } else {
    return <div>Loading</div>
  }
}

export default App;
